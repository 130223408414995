const Routes = {
  home: "/",
  profile: "/my-profile",
  userProfile: "/user-profile",
  termsOfService: "/terms-of-service",
  privacyPolicy: "/privacy-policy",
  deletionDetails: "/deletion-details",
  editProfile: "/edit-profile",
  createNFT: "/create-NFT",
  newPost: "/new-post",
  nftDetail: "/nft-detail",
  termsandcodition: "/terms-and-condition",
  disclaimer: "/disclaimer",
  about: "/purchaser-creator-agreement",
  landing: "/landing",
};

export default Routes;
