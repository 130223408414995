import React, { useCallback, useEffect, useState } from "react";
import { StyledHeader } from "./style";

import { Link, useNavigate, useLocation } from "react-router-dom";
import WalletForm from "../walletFormModal/walletForm";
import { useDispatch, useSelector } from "react-redux";
import Routes from "constants/routes";
import { getChainId } from "common/helpers/web3Helpers";
import { setChainId } from "store/commonStore/actionCreator";
import {
  login,
  connectAndSendSignatureRequest,
} from "store/loginStore/actions";
import { getUserApi } from "services/userServices";
import MenuDrawer from "./components/menuDrawer/menuDrawer";
import CustomButton from "components/CustomButton";
import MenuBarIcon from "assets/icons/menuBarIcon";
import AppLogo from "assets/logos/appLogo";
import HeaderSearch from "./components/headerSearch/headerSearch";
import { toast } from "react-toastify";
import { STORAGES, SUPPORTED_THEMES } from "constants/appConstants";
import SunIcon from "assets/icons/sunIcon";
import MoonIcon from "assets/icons/moonIcon";
import { updateThemeInLocalAndRedux } from "store/commonStore/actions";
import {
  startLoginLoading,
  stopLoginLoading,
} from "store/loginStore/actionCreator";
import {
  getItemFromSession,
  removeItemFromSession,
} from "common/helpers/sessionStorageHelpers";

const Header = () => {
  const [isDrawrOpen, setDrawrOpen] = useState(false);

  const [registerFormVisibility, setRegisterFormVisibility] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const { web3Instance, theme } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state) => state.login);

  const NewPostClickHandler = () => {
    if (isLogin) {
      navigate(Routes.createNFT);
    } else {
      toast("Connect your wallet to mint NFT!", {
        position: "top-right",
      });
    }
  };

  const showRegisterForm = useCallback(() => {
    setRegisterFormVisibility(true);
  }, []);

  useEffect(() => {
    const isHomepage = location.pathname.split("?")[0] === Routes.home;
    if (getItemFromSession(STORAGES.formLinkClicked) && isHomepage) {
      showRegisterForm();
      removeItemFromSession(STORAGES.formLinkClicked);
    }
  }, [location, showRegisterForm]);

  const hideRegisterForm = useCallback(() => {
    setRegisterFormVisibility(false);
  }, []);

  const toggleDrawer = useCallback(() => {
    setDrawrOpen((prev) => !prev);
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawrOpen(false);
  }, []);

  const themeChangeHandler = useCallback(() => {
    const newTheme =
      theme === SUPPORTED_THEMES.dark
        ? SUPPORTED_THEMES.light
        : SUPPORTED_THEMES.dark;
    dispatch(updateThemeInLocalAndRedux(newTheme));
  }, [dispatch, theme]);

  const walletConnectHandler = async () => {
    const hasMetamask = typeof window.ethereum !== "undefined";
    if (hasMetamask) {
      dispatch(startLoginLoading());
      const curChainId = await getChainId(web3Instance);
      dispatch(setChainId(curChainId));

      dispatch(
        connectAndSendSignatureRequest({
          onRequestSuccess: async ({ address, signature, message }) => {
            try {
              const userResponse = await getUserApi(address);

              const { data: result } = userResponse || {};
              const { name, username, email } = result.data;

              dispatch(
                login({
                  account: address,
                  signature,
                  message,
                  name,
                  email,
                  username,
                  onError: showRegisterForm,
                })
              );
            } catch (error) {
              showRegisterForm();
            } finally {
              dispatch(stopLoginLoading());
            }
          },
        })
      );
    } else {
      toast("Please install Metamask", {
        position: "top-right",
        type: "error",
      });
    }
  };
  const url = window.location.href.split("/");
  const [isLanding, setIsLanding] = useState(false);
  useEffect(() => {
    setIsLanding(false);
    for (let i = 0; i < url.length; i++) {
      if ("/" + url[i] === Routes.landing) {
        setIsLanding(true);
      }
    }
  }, [url]);
  const hanldeHome = () => {
    window.sessionStorage.setItem("landing", true);

    navigate("/");
  };

  return (
    <>
      <StyledHeader
        className="app-header"
        style={{ justifyContent: "space-between", display: "flex" }}
      >
        <Link to="/">
          <AppLogo />
        </Link>

        <div className="actions-wrapper">
          {isLanding ? (
            <>
              <CustomButton
                onClick={hanldeHome}
                className="app-launch-btn"
                color="gradient"
              >
                Enter App
              </CustomButton>
              <CustomButton
                onClick={themeChangeHandler}
                className="theme-button"
                color="gradient"
                outline
              >
                {theme === SUPPORTED_THEMES.dark ? (
                  <SunIcon className="theme-icon" />
                ) : (
                  <MoonIcon className="theme-icon" />
                )}
              </CustomButton>
            </>
          ) : (
            <>
              <HeaderSearch />
              <CustomButton
                onClick={themeChangeHandler}
                className="theme-button"
                color="gradient"
                outline
              >
                {theme === SUPPORTED_THEMES.dark ? (
                  <SunIcon className="theme-icon" />
                ) : (
                  <MoonIcon className="theme-icon" />
                )}
              </CustomButton>
              <span className="menu-icon-wrap" onClick={toggleDrawer}>
                <MenuBarIcon />
              </span>
            </>
          )}

          {/* <span className="menu-icon-wrap" onClick={toggleDrawer}>
            <MenuBarIcon />
          </span> */}
        </div>
      </StyledHeader>

      <MenuDrawer
        show={isDrawrOpen}
        onWalletConnect={walletConnectHandler}
        onNewPostClick={NewPostClickHandler}
        onItemClick={closeDrawer}
        onHide={closeDrawer}
      />

      <WalletForm
        centered
        show={registerFormVisibility}
        onHide={hideRegisterForm}
      />
    </>
  );
};

export default Header;
