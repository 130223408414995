import React from "react";
import PropTypes from "prop-types";

function BrokenLinkIcon({ className, fillColor = "currentColor" }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`broken-link-icon${className ? ` ${className}` : ""}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1263 0.908081L17.0902 1.87195C18.3016 3.08337 18.3016 5.06677 17.0902 6.2782L14.537 8.83142C13.5819 9.78797 12.1449 9.98718 10.9862 9.43494L15.5067 4.91443C15.9535 4.46765 15.9535 3.73816 15.5067 3.29285L14.6322 2.41834C14.1854 1.97156 13.4574 1.97156 13.0106 2.41834L8.51795 6.90955C8.02429 5.7699 8.23963 4.39002 9.16687 3.46277L11.7216 0.908081C12.933 -0.303345 14.9149 -0.303345 16.1263 0.908081ZM11.7084 17.1093L12.6429 16.9159L12.0394 14.0023L11.1063 14.1986L11.7084 17.1093ZM5.32898 0.314819L6.2533 0.0863038L6.97839 2.96619L6.05408 3.20056L5.32898 0.314819ZM15.0673 15.4965L15.7308 14.8124L13.5966 12.7411L12.933 13.4266L15.0673 15.4965ZM17.2294 12.4159L17.4755 11.496L14.6044 10.7255L14.3568 11.6469L17.2294 12.4159ZM0.371948 5.9281L0.637085 5.01257L3.49353 5.82556L3.23425 6.74402L0.371948 5.9281ZM1.95984 2.51501L2.63513 1.84119L4.73572 3.94324L4.06335 4.61853L1.95984 2.51501ZM0.908081 16.1263L1.87195 17.0902C3.08337 18.3016 5.06531 18.3016 6.2782 17.0902L8.83142 14.537C9.78797 13.5819 9.98718 12.1449 9.43494 10.9862L4.91443 15.5067C4.46765 15.9535 3.73816 15.9535 3.29285 15.5067L2.41834 14.6322C1.97156 14.1854 1.97156 13.4559 2.41834 13.0092L6.91101 8.51795C5.7699 8.02429 4.39148 8.23962 3.46277 9.16834L0.908081 11.7216C-0.303345 12.933 -0.303345 14.9149 0.908081 16.1263Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default BrokenLinkIcon;
BrokenLinkIcon.propTypes = {
  className: PropTypes.string,
  fillColor: PropTypes.string,
};
