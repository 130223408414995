import React from "react";
import PropTypes from "prop-types";

function YoutubeIcon({ fillColor = "currentColor", className = "" }) {
  return (
    <svg
      className={`youtube-icon${className ? ` ${className}` : ""}`}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill={fillColor}
    >
      <path d="M23.5001 3.50708C23.3642 3.02231 23.0995 2.58342 22.7341 2.23708C22.3584 1.88008 21.8979 1.62471 21.3961 1.49508C19.5181 1.00008 11.9941 1.00008 11.9941 1.00008C8.85746 0.964389 5.72156 1.12135 2.60413 1.47008C2.10232 1.60929 1.64269 1.87036 1.26613 2.23008C0.896129 2.58608 0.628129 3.02508 0.488129 3.50608C0.151822 5.31782 -0.0115791 7.15742 0.000128902 9.00008C-0.0118711 10.8411 0.151129 12.6801 0.488129 14.4941C0.625129 14.9731 0.892129 15.4101 1.26313 15.7631C1.63413 16.1161 2.09613 16.3711 2.60413 16.5061C4.50713 17.0001 11.9941 17.0001 11.9941 17.0001C15.1348 17.0358 18.2747 16.8789 21.3961 16.5301C21.8979 16.4004 22.3584 16.1451 22.7341 15.7881C23.1041 15.4351 23.3671 14.9961 23.4991 14.5181C23.8442 12.707 24.012 10.8667 24.0001 9.02308C24.0261 7.17168 23.8585 5.32264 23.5001 3.50608V3.50708ZM9.60213 12.4241V5.57708L15.8621 9.00108L9.60213 12.4241Z" />
    </svg>
  );
}

export default YoutubeIcon;

YoutubeIcon.propTypes = {
  fillColor: PropTypes.string,
  classNAme: PropTypes.string,
};
