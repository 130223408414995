import { convertPxToRem } from "common/helpers";

export const commonColors = {
  black: "#1D1D1D",
  white: "#ffffff",
  red: "#F86D6D",
  lightGray: "#E1E1E1",
  darkGray: "#888888",
  lightGray2: "#F1F1F1",
  primaryColor: "#005bea",
  gray: "#888888",
};

export const HeaderHeight = "95px";

const getBaseTheme = (themeColors) => ({
  palette: {
    common: {
      border: {
        dark: "#282828",
        light: "#E1E1E1",
        gray: commonColors.gray,
        lightGray: commonColors.lightGray,
      },
      card: {
        backgroundColor: "#E5E5E5",
        hover: "#00000011",
      },
      light: themeColors.light,
      red: commonColors.red,
      bgConnectBtn: "rosybrown",
      gray: "#b1b1b1",
      contrast: commonColors.black,
      matched: commonColors.white,
      lightBlue: "#4BC6FB",
      lightGray: commonColors.lightGray,
      lightGray2: commonColors.lightGray2,
      gray2: "#B7B7B7",
      darkGray: commonColors.darkGray,
      error: "#f44336",
      warning: "#ff9800",
      success: "#4caf50",
      info: "#2196f3",
      errorLight: "#e57373",
      warningLight: "#ffb74d",
      successLight: "#81c784",
      infoLight: "#64b5f6",
      yellow: "#f2a92c",
      shadows0: "0px 6px 10px rgba(255, 255, 255, 0.05)",
      shadows1: "0px 5px 10px rgba(255, 255, 255, 0.1)",
      white: "#fff",
      carosuelIndicatorGray: "#707070",
      backgroundColor: commonColors.white,
      gradientBackground: themeColors.primaryBackgroundGradient,
      animationBg: "#080b35",
      ...commonColors,
    },
    primary: {
      main: commonColors.primaryColor,
      light: "#5999ff",
      text: commonColors.white,
      textAlt: commonColors.black,
    },
    secondary: {
      main: "#00c6fb",
      light: "#00c6fb",
      text: commonColors.black,
      textAlt: commonColors.white,
    },
    tertiary: {
      main: "#1a1a1c",
      light: "#121213",
      text: commonColors.white,
      textAlt: commonColors.black,
    },
    gradient: {
      main: themeColors.primaryBackgroundGradient,
      light: "#00223E",
      text: commonColors.white,
      textAlt: commonColors.primaryColor,
    },
    white: {
      main: commonColors.white,
      light: "#dddddd",
      text: commonColors.black,
      textAlt: commonColors.white,
    },
    black: {
      main: commonColors.black,
      light: "#333333",
      light2: "#131314",
      text: commonColors.white,
      textAlt: commonColors.black,
    },
    text: {
      primary: commonColors.black,
      secondary: "#333333",
      lightGray2: commonColors.lightGray2,
      tertiary: "#ABABAB",
      gold: "#feefa4",
      light: commonColors.white,
      white: commonColors.white,
      gray: "#919191",
      darkGray: commonColors.darkGray,
      pink: "#fff0cc",
      dark: commonColors.black,
      error: commonColors.red,
      gray2: "#B7B7B7",
      blue: "#080b35",
    },
    selectMenu: { bg: "#fff", hover: "#EEF0F2", selected: "#EEF0F2" },
  },
  spacing: {
    unit: convertPxToRem(8),
    unit2: convertPxToRem(16),
    unit3: convertPxToRem(24),
    unit4: convertPxToRem(32),
  },
  shape: {
    borderRadius: {
      unit: convertPxToRem(8),
    },
  },
  typography: {
    title1: convertPxToRem(24),
    title2: convertPxToRem(28),
    title3: convertPxToRem(32),
    title4: convertPxToRem(36),
    title5: convertPxToRem(42),
    title6: convertPxToRem(52),
    subTitle1: convertPxToRem(20),
    subTitle2: convertPxToRem(22),
    subTitle3: convertPxToRem(24),
    subTitle4: convertPxToRem(26),
    text: convertPxToRem(14),
    text1: convertPxToRem(16),
    text2: convertPxToRem(18),
    subText1: convertPxToRem(11),
    subText2: convertPxToRem(12),
    subText3: convertPxToRem(13),
    subText4: convertPxToRem(14),
    subText5: convertPxToRem(15),
  },
  navbar: {
    height: convertPxToRem(80),
  },
});

export default getBaseTheme;
