import React from "react";
import PropTypes from "prop-types";

function SearchIcon({ fillColor = "currentColor", className = "" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`search-icon${className ? ` ${className}` : ""}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.60002 2.79985C6.32698 2.79985 5.10609 3.30556 4.20591 4.20573C3.30574 5.10591 2.80002 6.32681 2.80002 7.59985C2.80002 8.87288 3.30574 10.0938 4.20591 10.994C5.10609 11.8941 6.32698 12.3998 7.60002 12.3998C8.87306 12.3998 10.094 11.8941 10.9941 10.994C11.8943 10.0938 12.4 8.87288 12.4 7.59985C12.4 6.32681 11.8943 5.10591 10.9941 4.20573C10.094 3.30556 8.87306 2.79985 7.60002 2.79985ZM0.400025 7.59985C0.39988 6.46669 0.667195 5.3495 1.18023 4.33914C1.69326 3.32877 2.43753 2.45376 3.3525 1.78528C4.26747 1.11679 5.3273 0.6737 6.4458 0.492045C7.5643 0.31039 8.70988 0.395299 9.78938 0.739866C10.8689 1.08443 11.8518 1.67893 12.6582 2.475C13.4646 3.27107 14.0718 4.24625 14.4302 5.3212C14.7887 6.39616 14.8884 7.54056 14.7212 8.66131C14.554 9.78206 14.1246 10.8475 13.468 11.771L19.2484 17.5514C19.467 17.7778 19.588 18.0809 19.5852 18.3955C19.5825 18.7102 19.4563 19.0111 19.2338 19.2336C19.0113 19.4561 18.7103 19.5823 18.3957 19.5851C18.0811 19.5878 17.7779 19.4668 17.5516 19.2482L11.7724 13.469C10.6953 14.235 9.42804 14.6896 8.10963 14.7832C6.79123 14.8767 5.47251 14.6056 4.29798 13.9994C3.12346 13.3932 2.13845 12.4755 1.45089 11.3467C0.763337 10.2179 0.399768 8.92157 0.400025 7.59985Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.60002 2.79985C6.32698 2.79985 5.10609 3.30556 4.20591 4.20573C3.30574 5.10591 2.80002 6.32681 2.80002 7.59985C2.80002 8.87288 3.30574 10.0938 4.20591 10.994C5.10609 11.8941 6.32698 12.3998 7.60002 12.3998C8.87306 12.3998 10.094 11.8941 10.9941 10.994C11.8943 10.0938 12.4 8.87288 12.4 7.59985C12.4 6.32681 11.8943 5.10591 10.9941 4.20573C10.094 3.30556 8.87306 2.79985 7.60002 2.79985ZM0.400025 7.59985C0.39988 6.46669 0.667195 5.3495 1.18023 4.33914C1.69326 3.32877 2.43753 2.45376 3.3525 1.78528C4.26747 1.11679 5.3273 0.6737 6.4458 0.492045C7.5643 0.31039 8.70988 0.395299 9.78938 0.739866C10.8689 1.08443 11.8518 1.67893 12.6582 2.475C13.4646 3.27107 14.0718 4.24625 14.4302 5.3212C14.7887 6.39616 14.8884 7.54056 14.7212 8.66131C14.554 9.78206 14.1246 10.8475 13.468 11.771L19.2484 17.5514C19.467 17.7778 19.588 18.0809 19.5852 18.3955C19.5825 18.7102 19.4563 19.0111 19.2338 19.2336C19.0113 19.4561 18.7103 19.5823 18.3957 19.5851C18.0811 19.5878 17.7779 19.4668 17.5516 19.2482L11.7724 13.469C10.6953 14.235 9.42804 14.6896 8.10963 14.7832C6.79123 14.8767 5.47251 14.6056 4.29798 13.9994C3.12346 13.3932 2.13845 12.4755 1.45089 11.3467C0.763337 10.2179 0.399768 8.92157 0.400025 7.59985Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default SearchIcon;

SearchIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};
