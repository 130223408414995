import React from "react";
import PropTypes from "prop-types";

const FacebookIcon = ({
  fillColor = "currentColor",
  className = "",
  ...props
}) => {
  return (
    <svg
      width="15"
      height="25"
      viewBox="0 0 15 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`facebook-icon${className ? ` ${className}` : ""}`}
      {...props}
    >
      <path
        d="M14.9583 1.66668C14.9583 1.48987 14.8903 1.3203 14.7692 1.19527C14.6481 1.07025 14.4838 1.00001 14.3125 1.00001H11.0833C9.45728 0.916398 7.86527 1.50053 6.65514 2.62478C5.44501 3.74903 4.71514 5.322 4.625 7.00001V10.6H1.39583C1.22455 10.6 1.06028 10.6702 0.93916 10.7953C0.818043 10.9203 0.75 11.0899 0.75 11.2667V14.7333C0.75 14.9102 0.818043 15.0797 0.93916 15.2047C1.06028 15.3298 1.22455 15.4 1.39583 15.4H4.625V24.3333C4.625 24.5102 4.69304 24.6797 4.81416 24.8047C4.93528 24.9298 5.09955 25 5.27083 25H9.14583C9.31712 25 9.48139 24.9298 9.60251 24.8047C9.72362 24.6797 9.79167 24.5102 9.79167 24.3333V15.4H13.1758C13.3195 15.4021 13.4597 15.3548 13.5743 15.2654C13.6889 15.1761 13.7714 15.0499 13.8087 14.9067L14.7387 11.44C14.7644 11.3415 14.7679 11.2382 14.7488 11.1381C14.7298 11.038 14.6887 10.9438 14.6288 10.8626C14.569 10.7815 14.4918 10.7156 14.4034 10.6701C14.3149 10.6246 14.2176 10.6006 14.1187 10.6H9.79167V7.00001C9.82379 6.66998 9.97394 6.36416 10.2128 6.14227C10.4516 5.92038 10.762 5.79835 11.0833 5.80001H14.3125C14.4838 5.80001 14.6481 5.72977 14.7692 5.60475C14.8903 5.47972 14.9583 5.31015 14.9583 5.13334V1.66668Z"
        fill={fillColor}
      />
    </svg>
  );
};

FacebookIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default FacebookIcon;
