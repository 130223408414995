import {
  getLocalStorageItem,
  loginToLocalStorage,
  logoutFromLocalStorage,
} from "common/helpers/localStorageHelpers";
import {
  getAccount,
  getBalance,
  initProviderAndWeb3Instance,
  reqAccountAndSignature,
} from "common/helpers/web3Helpers";
import { STORAGES } from "constants/appConstants";
import { apiHandler } from "services/axios";
import {
  resetAccountDetails,
  setAccountDetails,
  setUserBalance,
  setUserData,
} from "store/userStore/actionCreators";
import { setLogin, startLoginLoading, stopLoginLoading } from "./actionCreator";
import { checkIfLogin } from "common/common";
import { getMessageApi, loginApi } from "services/loginServices";
import { getUserApi } from "services/userServices";
import { toast } from "react-toastify";

export const login = ({
  username,
  name,
  email,
  account,
  referralCode,
  signature,
  message,
  onError: onErrorCallback,
} = {}) => {
  return async (dispatch, getState) => {
    const { web3Instance } = getState().common;
    dispatch(startLoginLoading());

    await apiHandler(
      () =>
        loginApi({ username, name, email, signature, message, referralCode }),
      {
        onSuccess: async (result) => {
          const { user, token } = result?.data ?? {};

          loginToLocalStorage(token, user?.id);

          dispatch(setLogin({ isLogin: true, token, address: account }));

          getBalance(web3Instance, account).then((res) => {
            dispatch(setUserBalance({ eth: res.eth }));
          });
          toast(result?.message, {
            type: "success",
            position: "top-right",
          });
          dispatch(setUserData(user));
        },
        onError: (error, response) => {
          toast(error?.message, {
            type: "error",
            position: "top-right",
          });
          const _error =
            typeof response?.data.message === "string"
              ? response?.data.message
              : error;
          if (onErrorCallback) {
            onErrorCallback(_error);
          } else {
            throw new Error(_error);
          }
        },
        final: () => {
          dispatch(stopLoginLoading());
        },
      }
    );
  };
};

export const logout = (callback) => {
  return async (dispatch, getState) => {
    const isLogin = getState().login.isLogin;

    if (!isLogin) return;

    logoutFromLocalStorage();
    toast("Wallet disconnected successfully!", {
      type: "success",
      position: "top-right",
    });
    dispatch(resetAccountDetails());
    dispatch(setLogin({}));
    dispatch(setUserData(null));

    callback && callback();
  };
};

export const connectAndSendSignatureRequest = ({ onRequestSuccess } = {}) => {
  return async (dispatch, getState) => {
    const web3Instance = getState().common.web3Instance;

    const provider = web3Instance.currentProvider;
    await provider.enable();

    await apiHandler(() => getMessageApi(), {
      onSuccess: async (result) => {
        const message = result?.data.signinmessage;

        const [account, signature] = await reqAccountAndSignature(
          web3Instance,
          message
        );
        dispatch(setAccountDetails({ account, signature, message }));
        onRequestSuccess &&
          onRequestSuccess({ address: account, signature, message });
      },
      onError: (error) => {
        console.error(error);

        if (error?.code?.toString() === "4001") {
          console.error("signature rejected");
        }
        dispatch(stopLoginLoading());
      },
    });
  };
};

export const checkIfLoginAndUpdateState = (callback) => {
  return async (dispatch, getState) => {
    const token = getLocalStorageItem(STORAGES.token);

    const [web3Instance] = initProviderAndWeb3Instance({
      dispatch,
    });

    try {
      const address = await getAccount(web3Instance);

      if (checkIfLogin() && address) {
        dispatch(startLoginLoading());
        // get user balance and dispatch it to store
        const balance = await getBalance(web3Instance, address);
        apiHandler(() => getUserApi(address), {
          onSuccess: (result) => {
            const user = result?.data ?? {};

            dispatch(setUserData(user));
            dispatch(
              setAccountDetails({ address, signature: "", message: "" })
            );
            dispatch(setUserBalance({ eth: balance.eth }));
            dispatch(setLogin({ isLogin: true, token, address }));
          },
          onError: (error) => {
            console.error("error", error);
          },
        });
      } else {
        logoutFromLocalStorage();
      }
    } catch (error) {
      logoutFromLocalStorage();
      console.error(error);
    } finally {
      dispatch(stopLoginLoading());

      if (callback) callback();
    }
  };
};
