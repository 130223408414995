import React from "react";

function AppLogo() {
  return (
    <svg
      width="102"
      height="54"
      viewBox="0 0 102 54"
      fill="none"
      className="app-logo"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7229 32.3332C12.4047 31.9141 11.2451 31.7046 10.244 31.7046C9.12404 31.7046 8.2391 31.9141 7.58637 32.3332C6.93365 32.7523 6.60799 33.3357 6.60799 34.0819C6.60799 34.7587 6.83595 35.3222 7.29045 35.7767C7.74496 36.2312 8.31556 36.6036 9.00369 36.8953C9.6904 37.1869 10.6291 37.5069 11.8185 37.8566C13.4723 38.3465 14.8301 38.835 15.8906 39.3249C16.9497 39.8148 17.8545 40.5369 18.5992 41.4927C19.3454 42.4484 19.7178 43.7071 19.7178 45.2689C19.7178 46.7598 19.3171 48.0596 18.5114 49.1668C17.7072 50.2741 16.6 51.1194 15.1897 51.7013C13.7795 52.2832 12.1654 52.5749 10.3474 52.5749C8.43591 52.5749 6.57118 52.2195 4.75458 51.5087C2.93657 50.7979 1.35077 49.8479 0 48.6585L1.95819 44.6728C3.19285 45.814 4.58043 46.7131 6.11809 47.3644C7.65717 48.0171 9.09006 48.3442 10.4182 48.3442C11.7236 48.3442 12.7487 48.0936 13.4963 47.5924C14.2411 47.0925 14.6149 46.4101 14.6149 45.5478C14.6149 44.8483 14.3869 44.2707 13.9324 43.8162C13.4779 43.3617 12.9002 42.9949 12.2022 42.716C11.5027 42.4357 10.547 42.1213 9.33501 41.7716C7.67982 41.2817 6.32764 40.8045 5.27846 40.3387C4.22928 39.8729 3.33161 39.1678 2.58684 38.2234C1.84067 37.279 1.46828 36.0386 1.46828 34.501C1.46828 33.0794 1.84633 31.8377 2.60525 30.7772C3.36275 29.7167 4.43459 28.8954 5.82075 28.3121C7.20691 27.7301 8.81113 27.4385 10.6277 27.4385C12.2135 27.4385 13.7809 27.6721 15.3299 28.1379C16.8803 28.6052 18.2608 29.2338 19.4728 30.0267L17.5854 34.1173C16.3267 33.347 15.0382 32.7523 13.7229 32.3332Z"
        fill="#00B0F0"
      />
      <path
        d="M69.9382 32.3332C68.62 31.9141 67.4604 31.7046 66.4594 31.7046C65.3408 31.7046 64.4545 31.9141 63.8017 32.3332C63.149 32.7523 62.8233 33.3357 62.8233 34.0819C62.8233 34.7587 63.0499 35.3222 63.5058 35.7767C63.9617 36.2312 64.5309 36.6036 65.219 36.8953C65.9058 37.1869 66.8445 37.5069 68.0338 37.8566C69.6876 38.3465 71.0455 38.835 72.106 39.3249C73.1665 39.8148 74.0698 40.5369 74.8146 41.4927C75.5608 42.4484 75.9331 43.7071 75.9331 45.2689C75.9331 46.7598 75.531 48.0596 74.7268 49.1668C73.9226 50.2741 72.8153 51.1194 71.4051 51.7013C69.9949 52.2832 68.3807 52.5749 66.5627 52.5749C64.6513 52.5749 62.7865 52.2195 60.9685 51.5087C59.1505 50.7979 57.5647 49.8479 56.2139 48.6585L58.1721 44.6728C59.4068 45.814 60.7944 46.7131 62.332 47.3644C63.8697 48.0171 65.304 48.3442 66.6321 48.3442C67.9376 48.3442 68.9627 48.0936 69.7089 47.5924C70.4536 47.0925 70.8274 46.4101 70.8274 45.5478C70.8274 44.8483 70.5994 44.2707 70.1449 43.8162C69.6904 43.3617 69.1128 42.9949 68.4147 42.716C67.7153 42.4357 66.7595 42.1213 65.5475 41.7716C63.8923 41.2817 62.5402 40.8045 61.4924 40.3387C60.4432 39.8729 59.547 39.1678 58.8008 38.2234C58.0546 37.279 57.6822 36.0386 57.6822 34.501C57.6822 33.0794 58.0603 31.8377 58.8192 30.7772C59.5767 29.7167 60.6485 28.8954 62.0361 28.3121C63.4223 27.7301 65.0251 27.4385 66.8431 27.4385C68.4289 27.4385 69.9949 27.6721 71.5453 28.1379C73.0957 28.6052 74.4762 29.2338 75.6882 30.0267L73.8008 34.1173C72.5435 33.347 71.255 32.7523 69.9382 32.3332Z"
        fill="#00B0F0"
      />
      <path
        d="M80.3734 27.8237H85.0586V38.2773H97.2608V27.8237H101.945V52.2961H97.2594V42.3678H85.0572V52.2961H80.372V27.8237H80.3734Z"
        fill="#00B0F0"
      />
      <path
        d="M37.214 45.756C40.8244 45.756 43.7512 42.8292 43.7512 39.2188C43.7512 35.6084 40.8244 32.6816 37.214 32.6816C33.6036 32.6816 30.6768 35.6084 30.6768 39.2188C30.6768 42.8292 33.6036 45.756 37.214 45.756Z"
        fill="white"
      />
      <path
        d="M37.2141 0C30.6117 0 25.2597 5.35209 25.2597 11.9544C25.2597 16.641 27.9626 20.6863 31.8889 22.6459V18.2141C30.1176 16.7062 28.9905 14.4634 28.9905 11.9544C28.9905 7.41222 32.6719 3.73089 37.2141 3.73089C41.7563 3.73089 45.4376 7.41222 45.4376 11.9544C45.4376 14.4634 44.3106 16.7062 42.5393 18.2141V22.6459C46.4655 20.6863 49.1685 16.641 49.1685 11.9544C49.1685 5.35351 43.8164 0 37.2141 0Z"
        fill="white"
      />
      <path
        d="M39.6805 24.7331V16.6724C41.3796 15.7832 42.5406 14.0048 42.5406 11.9546C42.5406 9.01376 40.1563 6.62939 37.2154 6.62939C34.2746 6.62939 31.8903 9.01376 31.8903 11.9546C31.8903 14.0048 33.0513 15.7832 34.7504 16.6724V24.7331C27.804 25.9082 22.5071 31.9386 22.5071 39.2191C22.5071 47.3421 29.0924 53.9274 37.2154 53.9274C45.3385 53.9274 51.9238 47.3421 51.9238 39.2191C51.9224 31.9386 46.6269 25.9082 39.6805 24.7331ZM37.214 49.3144C31.6396 49.3144 27.1201 44.7949 27.1201 39.2205C27.1201 33.6461 31.6396 29.1266 37.214 29.1266C42.7884 29.1266 47.308 33.6461 47.308 39.2205C47.308 44.7949 42.7884 49.3144 37.214 49.3144Z"
        fill="#00B0F0"
      />
    </svg>
  );
}

export default AppLogo;
