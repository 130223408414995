import SectionWrapper from "components/sectionWrapper";
import { StyledTermsWrapper } from "./style";

function TermsAndConditions() {
  return (
    <SectionWrapper>
      <StyledTermsWrapper>
        <h1 className="page-heading">Terms and Conditions for sosh-nft</h1>

        <h3 className="page-sub-heading"> Introduction </h3>
        <p classname="body-text">
          These Website Standard Terms and Conditions written on this webpage
          shall manage your usage of the website,
          https://sosh-nft.oodleslab.com/ accessible by using
          https://sosh-nft.oodleslab.com/. These Terms will be applied fully and
          may affect your usage of this Website. By using this Website, you
          agreed for accepting all terms and conditions written here used
          attached terms of service applied fully and may affect your usage of
          this Website. By using this Website, you agreed for accepting all
          terms and conditions written here. You must not use this Website if
          you disagree with any of these Terms. You must not use this Website if
          you disagree with any of these Terms. below 18 years old people are
          not allowed to use this Website.
        </p>
        <h3 className="page-sub-heading"> Intellectual Property Rights </h3>
        <p classname="body-text">
          Other than the content you own, under these Terms, sosh-nft and/or its
          licensors own all the intellectual property rights and materials
          contained in this Website. You are granted a limited license only for
          the purpose of viewing the content of this Website.{" "}
        </p>
        <h3 className="page-sub-heading"> Restrictions </h3>
        <p classname="body-text">
          You are specifically restricted for any of these actions mentioned
          below: publishing any Website material in any other content;
          sublicensing, selling, and/or otherwise commercializing any Website
          content; publicly performing and/or showing any Website content; using
          this Website in any way that is or may create damage to this Website;
          using this Website in any way that changes user access to this
          Website; using this Website contrary to applicable regulations and
          laws, or in any way can cause harm to this Website, to any person or
          business entity; engaging in any data mining, data harvesting, data
          extracting, or any other similar activity for this Website; using this
          Website for engaging in any kind of advertising or marketing. Certain
          areas of this Website are restricted from being accessed by you and
          sosh-nft may further restrict access by you to any areas of this
          Website, at any time, in absolute discretion. Any user ID and password
          you may have for accessing this Website are confidential and you must
          maintain confidentiality from your side.{" "}
        </p>
        <h3 className="page-sub-heading"> Your Content </h3>
        <p classname="body-text">
          In these Website Standard Terms and Conditions, "Your Content" shall
          mean any video, audio, text, images, or other material you choose to
          display on this Website. By displaying Your Content, you grant
          sosh-nft a non-exclusive, worldwide irrevocable, sub-licensable
          license to reproduce, use, adapt, translate, publish, and distribute
          it in any and all media. You must be the owner of the content and must
          not be invading any third-party’s rights. sosh-nft reserves the right
          to remove any Content from this Website at any time without notice
          that belongs to you.{" "}
        </p>
        <h3 className="page-sub-heading"> Your Privacy </h3>
        <p classname="body-text">Please read Privacy Policy.</p>
        <h3 className="page-sub-heading"> No warranties </h3>
        <p classname="body-text">
          This Website is provided "as is," with all faults, and sosh-nft
          express no representations or warranties, of any kind related to this
          Website or the materials contained on this Website. Also, nothing
          contained on this Website shall be interpreted as advising you.
        </p>
        <h3 className="page-sub-heading"> Limitation of liability</h3>
        <p classname="body-text">
          In no event shall sosh-nft, nor any of its directors, officers, and
          employees shall be held liable for anything arising out of or in any
          way connected with your use of this Website whether such liability is
          under contract. sosh-nft, including its officers, employees, and
          directors shall not be held liable for any , consequential, indirect,
          or special liability arising out of or in any way related to your use
          of this Website.
        </p>
        <h3 className="page-sub-heading"> Indemnification</h3>
        <p classname="body-text">
          You hereby indemnify to the fullest extent sosh-nft from and against
          any and/or all liabilities, demands, costs causes of action, damages,
          and expenses arising in any way related to your breach for any of the
          provisions of these Terms and conditions.
        </p>
        <h3 className="page-sub-heading"> Severability</h3>
        <p classname="body-text">
          If any provision of these Terms is found invalid under any applicable
          law, such provisions shall be removed without affecting the remaining
          provisions herein.
        </p>
        <h3 className="page-sub-heading"> Variation of Terms</h3>
        <p classname="body-text">
          sosh-nft is permitted to revise these Terms at any time as it sees
          fit, and by using this Website you are now expected to review these
          Terms regularly
        </p>
        <h3 className="page-sub-heading"> Assignment</h3>
        <p classname="body-text">
          The sosh-nft is allowed to transfer, assign, and subcontract its
          rights and/or obligations for these Terms without giving any prior
          notification. However, you are not allowed to assign, transfer or
          subcontract any of your rights and/or obligations under these Terms
          and conditions.
        </p>
        <h3 className="page-sub-heading"> Entire Agreement</h3>
        <p classname="body-text">
          These Terms constitute the entire agreement between sosh-nft and you
          in relation to your use of this Website and supersede all the prior
          agreements and its understandings.{" "}
        </p>
        <h3 className="page-sub-heading"> Governing Law & Jurisdiction</h3>
        <p classname="body-text">
          These Terms will be governed and interpreted by the laws of the State
          of in, and you submit to the non-exclusive jurisdiction of the state
          and federal courts located in for the resolution of any disputes.
        </p>
      </StyledTermsWrapper>
    </SectionWrapper>
  );
}

export default TermsAndConditions;
