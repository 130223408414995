import {
  SUPPORTED_BLOCKCHAINS,
  SUPPORTED_ETHEREUM_NETWORKS,
  SUPPORTED_NETWORKS,
} from "constants/appConstants";

const defaultConfig = {
  apiBaseUrl: "https://www.soshnft.io/api/V1",
  // apiBaseUrl: "https://e31b-125-63-73-50.ngrok-free.app/api/V1",

  sepoliaHashUrl: "https://testnet.avascan.info/blockchain/c/tx/",
  cacheRefetchTime: 1000 * 60 * 30,
  cacheVersion: 1, // should be equal to our app version
  web3ProviderFallbackUrl: "http://localhost:8545",
  currentChainId:
    SUPPORTED_NETWORKS[SUPPORTED_BLOCKCHAINS.avax][
      SUPPORTED_ETHEREUM_NETWORKS.sepolia
    ].chain_id,
};

const getConfig = () => {
  const config = {
    ...defaultConfig,
    ...(window.__CONFIG__ || {}),
  };
  return config;
};

export default getConfig;
